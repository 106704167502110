/* eslint-disable no-console */
/* eslint-disable no-undef */
import RNInsider from 'react-native-insider';
import InsiderCallbackType from 'react-native-insider/src/InsiderCallbackType';
import RNInsiderIdentifier from 'react-native-insider/src/InsiderIdentifier';
import InsiderGender from 'react-native-insider/src/InsiderGender';
import { isMobileWeb, isNative } from '~global/screens';
import ProductUtils from '~global/utils/product';
import { Platform, NativeModules } from 'react-native';
import StringUtils from '~global/utils/string';

const Config = require('~config/default.env').default;
const isApps = isNative
  ? 'LILLA-APPS'
  : isMobileWeb
  ? 'LILLA-MOBILE-WEB'
  : 'LILLA-WEB';

const InsiderTracking = () => {
  const PARSE_INSIDER_TAXONOMY = (category) => {
    if (!category) {
      return ['undefined'];
    }

    const result = [];

    category.map((e) => {
      result.push(e.name);
    });

    return result;
  };

  const PARSE_INSIDER_PRODUCT = (data, selectedCombination) => {
    const Utils = new ProductUtils(data);

    const parseProduct = RNInsider.createNewProduct(
      String(data?.id ?? ''),
      data?.name ?? '',
      PARSE_INSIDER_TAXONOMY(data?.categories ?? []),
      data?.images?.[0]?.url ?? '',
      selectedCombination
        ? selectedCombination?.price ?? 0
        : data?.price ?? data?.default_combination?.price ?? 0,
      'IDR',
    );

    const dataCombination =
      selectedCombination ?? data?.default_combination ?? {};

    const attributeKey = Utils.getKeyCombinationName(dataCombination) ?? '';
    const attributeValue = Utils.getCombinationName(dataCombination) ?? '';
    const combination_id = dataCombination?._id ?? '';

    const stock = selectedCombination
      ? Utils.isInStockCombination(dataCombination)
      : Utils.isInStock();

    const unit_sale_price = selectedCombination
      ? selectedCombination?.price_after_discount ?? 0
      : data?.price_after_discount ??
        data?.default_combination?.price_after_discount ??
        0;

    const url = `${Config.LULLA_WEB_URL}/${StringUtils.slugify(
      data?.default_category?.name ?? '',
    )}/${data?.slug ?? ''}`;

    parseProduct
      .setCustomAttributeWithString('url', url)
      .setCustomAttributeWithString(attributeKey, attributeValue)
      .setCustomAttributeWithString(
        'brand_name',
        data?.brand?.name ?? 'undefined',
      )
      .setCustomAttributeWithInt(
        'unit_sale_price',
        unit_sale_price ??
          (selectedCombination
            ? selectedCombination?.price ?? 0
            : data?.price ?? data?.default_combination?.price ?? 0),
      )
      .setCustomAttributeWithString('combination_id', combination_id)
      .setCustomAttributeWithBoolean('is_out_of_stock_lilla', stock);

    return parseProduct;
  };

  const insiderInit = () => {
    RNInsider.init(
      Config.INSIDER_PARTNER_NAME,
      Config.APP_GROUP_IOS,
      (type, data) => {
        switch (type) {
          case InsiderCallbackType.NOTIFICATION_OPEN:
            if (__DEV__) {
              console.debug(
                `✨ --- \x1b[35m[${isApps}]\x1b[0m[INSIDER][NOTIFICATION_OPEN]: --- ✨`,
              );
              console.debug('\x1b[35m%s\x1b[0m', JSON.stringify(data, null, 2));
            }
            break;
          case InsiderCallbackType.TEMP_STORE_CUSTOM_ACTION:
            if (__DEV__) {
              console.debug(
                `🚀 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER][TEMP_STORE_CUSTOM_ACTION]: --- 🚀`,
              );
              console.debug('\x1b[35m%s\x1b[0m', JSON.stringify(data, null, 2));
            }
            break;
          case InsiderCallbackType.INAPP_SEEN:
            if (__DEV__) {
              console.debug(
                `👀 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER][INAPP_SEEN]: --- 👀`,
              );
              console.debug('\x1b[35m%s\x1b[0m', JSON.stringify(data, null, 2));
            }
            break;
        }
      },
    );

    RNInsider.registerWithQuietPermission(false);
    RNInsider.setActiveForegroundPushView();
    RNInsider.startTrackingGeofence();
    RNInsider.enableIDFACollection(false);
    RNInsider.enableIpCollection(false);
    RNInsider.enableLocationCollection(false);
    RNInsider.enableCarrierCollection(false);

    if (__DEV__) {
      console.debug(
        `🎉 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER][INITIALIZED]: \x1b[35m${Config.INSIDER_PARTNER_NAME}\x1b[0m --- 🎉`,
      );
    }
  };

  const insiderReInit = () => {
    RNInsider.reinitWithPartnerName(
      Config.INSIDER_PARTNER_NAME,
      Config.APP_GROUP_IOS,
    );
    RNInsider.registerWithQuietPermission(false);
    RNInsider.setActiveForegroundPushView();
    RNInsider.startTrackingGeofence();
    RNInsider.enableIDFACollection(false);
    RNInsider.enableIpCollection(false);
    RNInsider.enableLocationCollection(false);
    RNInsider.enableCarrierCollection(false);

    if (__DEV__) {
      console.debug(
        `🎉 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER][INITIALIZED]: Reinitialized \x1b[35m${Config.INSIDER_PARTNER_NAME}\x1b[0m --- 🎉`,
      );
    }
  };

  const insiderVisitHomepage = () => {
    RNInsider.visitHomePage();

    if (__DEV__) {
      console.debug(
        `👀 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Visit Home Page --- 👀`,
      );
    }
  };

  const insiderVisitProductDetailPage = (data, selectedCombination) => {
    RNInsider.visitProductDetailPage(
      PARSE_INSIDER_PRODUCT(data, selectedCombination),
    );

    if (__DEV__) {
      console.debug(
        `👀 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Visit PDP of \x1b[35m${data?.name}\x1b[0m --- 👀`,
      );
    }
  };

  const insidervisitCartPage = (data) => {
    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const parseProduct = RNInsider.createNewProduct(
          String(data[i].id),
          data[i].detail.name,
          PARSE_INSIDER_TAXONOMY(data[i]?.categories),
          data[i].detail.image?.url,
          Number(
            data[i].detail.combination.total_price ??
              data[i].detail.combination.price_after_discount,
          ),
          'IDR',
        );
        parseProduct.setQuantity(Number(data[i]?.quantity));

        RNInsider.visitCartPage([parseProduct]);
      }
    }

    if (__DEV__) {
      console.debug(
        `🛒 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Visit Cart Page --- 🛒`,
      );
    }
  };

  const insiderItemPurchased = (data) => {
    if (data.products?.length > 0) {
      const Utils = new ProductUtils(data);

      const listProduct = data.products;
      for (let i = 0; i < listProduct.length; i++) {
        const combination_id = listProduct[i].combination.id;

        delete listProduct[i].combination.id;
        delete listProduct[i].combination.name;

        const attributeKey = Utils.getKeyCombinationName(
          listProduct[i].combination,
        );

        const attributeValue = Utils.getCombinationName(
          listProduct[i].combination,
        );

        const parseProduct = RNInsider.createNewProduct(
          String(listProduct[i].id),
          listProduct[i].name,
          [
            listProduct[i].category_parent,
            listProduct[i].category_child,
            listProduct[i].category_grandchild,
          ],
          listProduct[i].images?.[0]?.url,
          Number(listProduct[i].unit_price),
          'IDR',
        );
        parseProduct
          .setQuantity(Number(listProduct[i]?.quantity))
          .setShippingCost(Number(data.total_shipping));

        parseProduct
          .setCustomAttributeWithString('url', listProduct[i].url)
          .setCustomAttributeWithString(
            'payment_method',
            String(data.payment_method.name),
          )
          .setCustomAttributeWithString(attributeKey, attributeValue)
          .setCustomAttributeWithInt('total', Number(data.total_paid))
          .setCustomAttributeWithInt(
            'subtotal',
            Number(data.total_amount_after_extra),
          )
          .setCustomAttributeWithString(
            'combination_id',
            String(combination_id),
          )
          .setCustomAttributeWithInt(
            'unit_sale_price',
            Number(listProduct[i].price_after_discount),
          );

        if (data.shipping?.delivery_type !== 'self_pick_up') {
          parseProduct
            .setCustomAttributeWithString('country', 'Indonesia')
            .setCustomAttributeWithString(
              'city',
              String(data?.shipping?.delivery_address.city.name),
            )
            .setCustomAttributeWithString(
              'district',
              String(data?.shipping?.delivery_address.district.name),
            );
        }

        RNInsider.itemPurchased(String(data._id), parseProduct);
      }
    }

    if (__DEV__) {
      console.debug(
        `🛍️ --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Item Purchased --- 🛍️`,
      );
    }
  };

  const insiderSignupConfirmation = () => {
    RNInsider.signUpConfirmation();

    if (__DEV__) {
      console.debug(
        `📝 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: SignUp Confirmation --- 📝`,
      );
    }
  };

  const insiderAddtoWishlist = (data) => {
    const addToWishListEvent = RNInsider.tagEvent('add_to_wishlist');

    addToWishListEvent
      .addParameterWithString(
        'default_category',
        String(data.default_category.name),
      )
      .addParameterWithString(
        'id',
        String(data.default_combination.tracker_combination_name),
      )
      .addParameterWithString('brand_name', String(data.brand.name))
      .addParameterWithArray(
        'taxonomy',
        PARSE_INSIDER_TAXONOMY(data.categories),
      )
      .addParameterWithString('product_image_url', String(data.images?.[0].url))
      .addParameterWithString('groupcode', String(data.id))
      .addParameterWithInt('unit_price', Number(data.default_combination.price))
      .addParameterWithDouble(
        'unit_sale_price',
        data.default_combination.price_after_discount ??
          data.default_combination.price,
      );

    const url = `${Config.LULLA_WEB_URL}/${StringUtils.slugify(
      data.default_category.name,
    )}/${data.slug}`;

    addToWishListEvent
      .addParameterWithString('name', String(data.name))
      .addParameterWithString('url', String(url));

    addToWishListEvent.build();

    if (__DEV__) {
      console.debug(
        `🎁 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Add to Wishlist of \x1b[35m${data?.name}\x1b[0m --- 🎁`,
      );
    }
  };

  const insiderBrandPageView = (brandName) => {
    const brandPageVisitEvent = RNInsider.tagEvent('brand_page_view');

    brandPageVisitEvent.addParameterWithString('brand_name', brandName);
    brandPageVisitEvent.build();

    if (__DEV__) {
      console.debug(
        `⛺️ --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Visit Brand Page \x1b[35m${brandName}\x1b[0m --- ⛺️`,
      );
    }
  };

  const insiderSearchEvent = (searchKeyword) => {
    const searchEvent = RNInsider.tagEvent('search');

    searchEvent.addParameterWithString('keyword', searchKeyword);
    searchEvent.build();

    if (__DEV__) {
      console.debug(
        `🔎 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Search Query \x1b[35m${searchKeyword}\x1b[0m --- 🔎`,
      );
    }
  };

  const insiderAddToCart = (data, selectedCombination) => {
    RNInsider.itemAddedToCart(PARSE_INSIDER_PRODUCT(data, selectedCombination));

    if (__DEV__) {
      console.debug(
        `🛒📍 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Add Product to Cart of \x1b[35m${data?.name}\x1b[0m --- 🛒📍`,
      );
    }
  };

  const insiderItemRemovedFromCart = (id) => {
    RNInsider.itemRemovedFromCart(String(id));

    if (__DEV__) {
      console.debug(
        `🗑️ --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Remove Product from Cart --- 🗑️`,
      );
    }
  };

  const insiderCartCleared = () => {
    RNInsider.cartCleared();

    if (__DEV__) {
      console.debug(
        `📉 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Clear Product from Cart --- 📉`,
      );
    }
  };

  const insiderIdentifiers = (data) => {
    const deviceLanguage =
      Platform.OS === 'ios'
        ? NativeModules.SettingsManager.settings.AppleLocale ||
          NativeModules.SettingsManager.settings.AppleLanguages[0]
        : NativeModules.I18nManager.localeIdentifier
        ? NativeModules.I18nManager.localeIdentifier
        : 'en';

    const currentUser = RNInsider.getCurrentUser();
    const identifiers = new RNInsiderIdentifier();

    identifiers.addEmail(data.email);
    identifiers.addPhoneNumber(data?.phone_no_e164 ?? data.phone_no);
    identifiers.addUserID(data._id);

    currentUser.login(identifiers);

    const userGender =
      data.gender === 'Female' ? InsiderGender.Female : InsiderGender.Male;
    const userAddress = data.address?.[0];

    currentUser
      .setName(data.name)
      .setSurname(`${data.first_name} ${data.last_name}`)
      .setEmailOptin(true)
      .setWhatsappOptin(true)
      .setPushOptin(true)
      .setLocationOptin(true)
      .setGender(userGender)
      .setLocale(deviceLanguage);

    currentUser
      .setCustomAttributeWithString('user_level', data.user_level)
      .setCustomAttributeWithInt('soco_point', data.points.count)
      .setCustomAttributeWithString('username', data.user_name)
      .setCustomAttributeWithBoolean(
        'is_verified_email',
        data.is_verified_email,
      )
      .setCustomAttributeWithBoolean(
        'is_verified_phone',
        data.is_verified_phone,
      )
      .setCustomAttributeWithBoolean(
        'beauty_created_at',
        data.beauty_created_at ? true : false,
      )
      .setCustomAttributeWithString('country', 'Indonesia')
      .setCustomAttributeWithString(
        'city',
        userAddress?.city.name ?? 'undefined',
      )
      .setCustomAttributeWithString(
        'district',
        userAddress?.district.name ?? 'undefined',
      )
      .setCustomAttributeWithString('username', data.user_name);

    if (__DEV__) {
      console.debug(
        `🕵🏻‍♂️ --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Identifiers Login --- 🕵🏻‍♂️`,
      );
    }
  };

  const insiderLogout = () => {
    RNInsider.getCurrentUser().logout();

    if (__DEV__) {
      console.debug(
        `🥷🏻 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Logout --- 🥷🏻`,
      );
    }
  };

  const insiderVisitListingPage = ({ parent, type, title, headerTitle }) => {
    const children =
      type === 'group'
        ? title
        : type === 'group-child'
        ? headerTitle
        : undefined;
    const grandChildren = type === 'group-child' ? title : undefined;
    const taxonomy = [parent, children, grandChildren].filter(Boolean);

    RNInsider.visitListingPage(taxonomy);

    if (__DEV__) {
      console.debug(
        `🪜 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Listing Page \x1b[35m${children}\x1b[0m --- 🪜`,
      );
    }
  };

  const insiderLoginEvent = async (session_id) => {
    const loggedInEvent = RNInsider.tagEvent('login');

    loggedInEvent.addParameterWithString('session_id', String(session_id));

    loggedInEvent.build();

    if (__DEV__) {
      console.debug(
        `🖼️ --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Login Event \x1b[35m${String(
          session_id,
        )}\x1b[0m --- 🖼️`,
      );
    }
  };

  const insiderSignupEvent = async () => {
    const signUpEvent = RNInsider.tagEvent('sign_up');

    signUpEvent.build();

    if (__DEV__) {
      console.debug(
        `📍 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: SignUp Event --- 📍`,
      );
    }
  };

  const insiderGetSmartRecommendation = async (id) => {
    if (!id) {
      return [];
    }

    return new Promise((resolve) => {
      RNInsider.getSmartRecommendation(
        id,
        'id_ID',
        'IDR',
        (smartRecommendation) => {
          try {
            const productId =
              smartRecommendation?.data?.map((item) => item?.groupcode) || [];

            __DEV__ &&
              console.debug(
                `🧠 --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Smart Recommendation (${productId.length} items) --- 🧠`,
              );

            resolve(productId);
          } catch {
            resolve([]);
          }
        },
      );
    });
  };

  const insiderClickRecomendation = (data) => {
    if (!data?.id || !data?.name) {
      return;
    }

    const productData = {
      id: String(data.id),
      name: data.name,
      taxonomy: PARSE_INSIDER_TAXONOMY(data.categories),
      imageUrl: data.images?.[0]?.url || '',
      price: data.price ?? data.default_combination?.price ?? 0,
      currency: 'IDR',
    };

    const parseProduct = RNInsider.createNewProduct(
      productData.id,
      productData.name,
      productData.taxonomy,
      productData.imageUrl,
      productData.price,
      productData.currency,
    );

    RNInsider.clickSmartRecommendationProduct(
      data.recomendationId,
      parseProduct,
    );

    __DEV__ &&
      console.debug(
        `🖍️ --- \x1b[35m[${isApps}]\x1b[0m[INSIDER]: Smart Recommendation Click: ${productData.name} --- 🖍️`,
      );
  };

  return {
    insiderInit,
    insiderReInit,
    insiderVisitHomepage,
    insiderVisitProductDetailPage,
    insidervisitCartPage,
    insiderItemPurchased,
    insiderSignupConfirmation,
    insiderAddtoWishlist,
    insiderBrandPageView,
    insiderSearchEvent,
    insiderItemRemovedFromCart,
    insiderCartCleared,
    insiderAddToCart,
    insiderIdentifiers,
    insiderLogout,
    insiderVisitListingPage,
    insiderLoginEvent,
    insiderSignupEvent,
    insiderGetSmartRecommendation,
    insiderClickRecomendation,
  };
};

export default InsiderTracking();
